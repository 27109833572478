import React from 'react';
import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase } from '@material-ui/core';

// project imports
import config from './../../../config';
import Logo from './../../../assets/images/4point.png';

//-----------------------|| MAIN LOGO ||-----------------------//

const LogoSection = () => {
    return (
        <ButtonBase disableRipple component={Link} to={config.defaultPath}>
             <img src={Logo} alt="4point Logo" style={{ width: '50px' }} />
        </ButtonBase>
    );
};

export default LogoSection;
