import { useEffect, useState } from 'react';
import { utilities } from './utilities';
import { IconChartDots } from '@tabler/icons';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import configData from '../config';

const fetchReports = async (token) => {
    try {
        // console.log("JWT Token:", token);
        const response = await axios.get(configData.API_SERVER + 'reports', {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        // console.log("Fetched Reports Data:", response.data);
        if (response.data.success) {
            return response.data.reports; // Access the 'reports' array
        } else {
            throw new Error('Failed to fetch reports');
        }
    } catch (error) {
        console.error('Error fetching reports:', error);
        return [];
    }
};

const ReportsMenuWrapper = () => {
    const [reports, setReports] = useState([]);
    const token = useSelector((state) => state.account.token);
    const history = useHistory();

    useEffect(() => {
        const loadReports = async () => {
            if (token) {
                const reportsData = await fetchReports(token);

                if (reportsData.length > 0) {
                    // Process the reports data directly, as the new API no longer returns a nested structure
                    const processedReports = reportsData.map(report => ({
                        id: `report-${report.id}`,
                        title: `${report.name} (${report.location})`,
                        type: 'item',
                        url: `/reports/${report.id}`, // Route to load all files in that report
                        icon: IconChartDots,
                        breadcrumbs: false,
                        onClick: () => {
                            // console.log('Item passed to ReportViewer:', report);
                            history.push(`/reports/${report.id}`);
                        }
                    }));

                    // console.log("Processed Report Items:", processedReports);
                    setReports(processedReports);
                    utilities.children = processedReports;
                } else {
                    console.log('No reports found.');
                }
            }
        };
        loadReports();
    }, [token, history]);

    if (!token || reports.length === 0) {
        return null;
    }

    return null;
};

export default ReportsMenuWrapper;