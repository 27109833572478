// import { IconWindmill } from '@tabler/icons';

// const icons = {
//     IconWindmill: IconWindmill,
// };

export const utilities = {
    id: 'reports',
    title: 'Reports',
    type: 'group',
    children: []
};