import PropTypes from 'prop-types';
import React, { useState } from 'react';

// material-ui
import { makeStyles, useTheme } from '@material-ui/styles';
import { Box, Drawer, useMediaQuery, Button } from '@material-ui/core';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
import { BrowserView, MobileView } from 'react-device-detect';

// project imports
import MenuList from './MenuList';
import LogoSection from '../LogoSection';
import MenuCard from './MenuCard';
import { drawerWidth } from './../../../store/constant';

// style constant
const useStyles = makeStyles((theme) => ({
    drawer: {
        [theme.breakpoints.up('md')]: {
            width: drawerWidth,
            flexShrink: 0
        }
    },
    drawerPaper: {
        width: drawerWidth,
        background: theme.palette.background.default,
        color: theme.palette.text.primary,
        borderRight: 'none',
        [theme.breakpoints.up('md')]: {
            top: '88px'
        }
    },
    ScrollHeight: {
        height: 'calc(100vh - 88px)',
        paddingLeft: '16px',
        paddingRight: '16px',
        [theme.breakpoints.down('sm')]: {
            height: 'calc(100vh - 56px)'
        }
    },
    boxContainer: {
        display: 'flex',
        padding: '16px',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    refreshButton: {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        textTransform: 'none',
        fontSize: '0.75rem',
        padding: '4px 10px',
        margin: '8px auto', // Center the button horizontally
        borderRadius: theme.shape.borderRadius,
        maxWidth: '150px',  // Set a maximum width for the button
        display: 'block',   // Make the button a block element for proper centering
        '&:hover': {
            backgroundColor: theme.palette.primary.dark
        }
    }
}));

//-----------------------|| SIDEBAR DRAWER ||-----------------------//

const Sidebar = ({ drawerOpen, drawerToggle, window }) => {
    const classes = useStyles();
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

    const [refreshKey, setRefreshKey] = useState(0); // Key to trigger rerender

    const handleRefresh = () => {
        setRefreshKey(prevKey => prevKey + 1); // Update the key to rerender
    };

    const drawer = (
        <React.Fragment>
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                <div className={classes.boxContainer}>
                    <LogoSection />
                </div>
            </Box>
            <Button
                onClick={handleRefresh}
                variant="contained"
                className={classes.refreshButton} // Apply custom styles
                size="small"
            >
                Refresh Reports
            </Button> {/* Rerender Button */}
            <BrowserView>
                <PerfectScrollbar component="div" className={classes.ScrollHeight}>
                    <MenuList key={refreshKey} />
                    <MenuCard />
                </PerfectScrollbar>
            </BrowserView>
            <MobileView>
                <Box sx={{ px: 2 }}>
                    <MenuList key={refreshKey} />
                    <MenuCard />
                </Box>
            </MobileView>
        </React.Fragment>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <nav className={classes.drawer} aria-label="mailbox folders">
            <Drawer
                container={container}
                variant={matchUpMd ? 'persistent' : 'temporary'}
                anchor="left"
                open={drawerOpen}
                onClose={drawerToggle}
                classes={{
                    paper: classes.drawerPaper
                }}
                ModalProps={{ keepMounted: true }}
                color="inherit"
            >
                {drawer}
            </Drawer>
        </nav>
    );
};

Sidebar.propTypes = {
    drawerOpen: PropTypes.bool,
    drawerToggle: PropTypes.func,
    window: PropTypes.object
};

export default Sidebar;
